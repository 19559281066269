.footer {
  bottom: 0;
  position: relative;
  background-color: #5c38d2;

  &__crater__tag {
    width: 100%;
    height: 100%;
    color: white;
    font-weight: bold;
  }

  &::after {
    content: "";
    position: absolute;
    background: transparent;
    opacity: 0.3;
    top: 4px;
    left: 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border-radius: 6px;
    box-shadow: 0 0 15px -5px black;
  }
}
