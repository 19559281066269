.profile__page {
  width: min(350px, 100%);
  min-height: 300px;
  border-radius: 8px;
  padding: 10px;
  background: radial-gradient(#5c38d29d, #5c38d2ce);
  display: flex;
  flex-direction: column;
  gap: 10px;
  &__avatar {
    width: calc(min(50%, 150px));
    border-radius: 50%;
    object-fit: cover;
    aspect-ratio: 1;
    &__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
  &__content__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
  &__button {
    &__container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 5px;
    }
    padding: 5px 10px;
    border-radius: 8px;
    &__update {
      background: white;
    }
    &__cancel {
      background: var(--danger-color);
    }
  }
}
