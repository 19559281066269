.editable__text {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  background: #aaaaaaaa;
  border-radius: 8px;
  padding: 3px;

  &__label {
    margin: 0;
  }
  &__container {
    margin: 0;
  }
  &__button {
    margin: 0;
    height: 100%;
    padding: 5px;
    aspect-ratio: 1;
    border: 1px solid var(--primary-color-light-1);
    border-radius: 5px;
    background: white;
    &__edit {
      margin: 0;
    }
    &__save {
      margin: 0;
    }
  }
  &__container {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  &__input {
    padding: 5px;
    border: 1px solid var(--primary-color-light-1);
    border-radius: 5px;
    background-color: white;
    color: var(--primary-color-dark-1);
    font-size: 1rem;
    font-weight: 500;
    flex-grow: 1;
  }
  &__value {
    margin: 0;
    border: black 1px solid;
    flex-grow: 1;
    padding: 5px;
    border: 1px solid var(--primary-color-light-1);
    border-radius: 5px;
    background-color: #ffffff7d;
    color: var(--primary-color-dark-1);
    font-size: 1rem;
    font-weight: 500;
    flex-grow: 1;
  }

  
}
