.auth__dropdown {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
  &__header {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
    &__user {
      white-space: nowrap;
    }
    &__roles {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
  }
  &__title {
    font-size: 1rem;
    font-weight: 600;
    // margin-bottom: 1rem;
  }
  &__options {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0rem;
    margin: 0rem;

    &__item {
      display: flex;
      justify-content: center;
      padding-inline: 10px;
      text-decoration: none;
      color: black;
      border-radius: 5px;
      box-shadow: 0 0 5px 0px #555555;
      filter: opacity(0.9);
      // &:active{
      //     color: black;
      // }
      &:hover {
        // background-color: var(--primary-color-light-2);
        filter: opacity(1);
      }
    }
  }
}
