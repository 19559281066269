.login__page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__auth {
    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__title {
      font-size: 32px;
      margin-bottom: 20px;
    }
    &__button {
      box-shadow: 0 0 5px 1px black;
      border-radius: 5px;
      padding: 10px;
      cursor: pointer;
      width: 300px;
      background: linear-gradient(
        to bottom,
        #0800ff51,
        #a1a6d954
      );
      margin-top: 20px;
      display: flex;
      gap: 10px;
      font-size: 1.5rem;
      text-transform: capitalize;
      img {
        width: 30px;
        height: 30px;
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
